<template>
  <div class="content">
    <!--  -->
    <div class="content_top">
      <div class="content_top_box">
        <div class="content_top_box_l">
          <div class="logo">
            <img src="../assets/image/logo.png" alt="" class="image" />
          </div>
          <div v-for="(item,index) in topTitleList" :key="index"
          @click="topBtn(index)"
            :class="item.show ? item.className : item.className_open">
           {{ item.title }}</div>
        </div>

        <div>
          <div class="content_top_box_r">
            <!--div class="content_top_box_r_image">
              <img src="../assets/image/Wallet.png" alt="" class="image" />
            </div-->
            <a href="#conctract">
              <div class="content_top_box_r_title">Contact Us</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->
    <!--  -->
    <div class="Bits">
      <div>
        <img src="../assets/image/Btis.png" alt="" class="image" />
      </div>
      <div class="walk">
        <img src="../assets/image/walk.png" alt="" class="image" />
      </div>
    </div>
    <!-- end -->
    <!--  -->
    <div class="theNext">
      <div class="theNextBox">
        <!--  -->
        <div class="theNextBox_title">The Next BRC20 Value Network</div>
        <div class="theNext_outside">
          <div class="theNext_interior"></div>
        </div>
        <!-- end -->
        <!-- VALUES -->
        <div>
          <div class="VALUES_title">VALUES</div>
          <!-- div class="right">
            <img src="../assets/image/right.png" alt="" class="image">
          </div -->
          <div class="VALUES_conter">
            <div>

              <div class="build ">Our vision is to become the basic currency in the “Bitcoin Ecosystem”, mainly used for interaction  between “Bitcoin Ecosystem” applications and digital infrastructure.</div>

            </div>
            <div class="values_r">
              <img src="../assets/image/values_r.png" alt="" class="image">
            </div>

          </div>

        </div>
        <!-- end -->
        <!-- FEATURES -->
        <div class="FEATURES_title">
            PLAN
        </div>
         <div class="FEATURES_conter">
          <div class="FEATURES_conter_one">
            <div class="one">01</div>
            <div class="one_title">Fast & Secure Infrastructure</div>
            <div class="one_content"> Build a  "Bit Infrastructure" like DSN (decentralized storage network） faster than the Bitcoin network POW (L1). </div>
          </div>
          <div class="FEATURES_conter_two">
            <div class="two">02</div>
            <div class="one_title" >User-friendly Access</div>
            <div class="one_content">Users can easily connect wallet to use “Bit Infrastructure” , This will make it easier for persons and enterprises to apply decentralized storage network.  </div>
          </div>
          <div class="FEATURES_conter_three">
            <div class="three">03</div>
            <div class="one_title" style="">Support multiple applications</div>
            <div class="one_content">Support more application scenarios, reducing costs and improving efficiency, more users are willing change their storage framework to a decentralized storage network.</div>

          </div>
          <div class="FEATURES_conter_four">
            <div class="four">04</div>
            <div class="one_title" style="margin:10px 0 14px 16px;">Bitcoin Economic Eco-system</div>
            <div class="one_content" style="height:234px;">
                Establish a more efficient economic model of producers (network storage providers) and consumers (network storage users) in the Bitcoin ecosystem.</div>

          </div>
        </div>
        <!-- end -->
        <!-- Friends -->
         <div class="Friends_title">FRIENDS</div>
          <!--div class="right">
            <img src="../assets/image/right.png" alt="" class="image">
          </div-->
        <div class="Friends_content">
          <div class="ardizor1" style=""></div>
          <div class="ardizor2"></div>
          <div class="ardizor3"></div>

        </div>
        <!-- end -->
        <!-- Contact Us -->
        <div class="Contact_title" id ="conctract">CONTACT US</div>
        <div class="mail">
          <div class="mail_text">Enter your E-mail</div>
          <div class="mail_r">
            <img src="../assets/image/mail_r.png" alt="" class="image">
          </div>
        </div>
        <div class="subscribing">By subscribing, you accepted our Policy</div>
        <!-- end -->
        <!-- bottom -->
        <div class="bottom" >
          <div class="bottom_logo">
            <img src="../assets/image/logo.png" alt="" class="image">
          </div>
          <div class="bottom_box">
            <div class="bottom_icon">
              <a href="https://t.me/bitsbrc20">
                <img src="../assets/image/bottom_icon1.png" title="Telegram" class="image">
              </a>
            </div>
           <div class="bottom_icon" style="margin-left:12px;">
             <a href="https://discord.gg/bitsclub">
              <img src="../assets/image/bottom_icon2.png" title="Discord" class="image">
             </a>
            </div>
            <div class="bottom_icon" style="margin-left:12px;">
                <a href="https://x.com/bits_X3?s=20">
                     <img src="../assets/image/bottom_icon3.png" title="Twitter" class="image">
                </a>
            </div>

          </div>
        </div>
        <!-- end -->
      </div>
    </div>
    <!-- end -->
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      topTitleList: [
        {
          anchor: "#About",
          className: "content_top_box_l_title",
          className_open: "content_top_box_l_titleOpen",
          title: "Join Bits",
          show: false,
        },

        {
          anchor: "https://unisat.io/market/brc20?tick=bits",
          title: "Buy Bits",
          className: "content_top_box_l_title",
          className_open: "content_top_box_l_titleOpen",
          show: true,
        },
         {
          anchor: "#Token",
          className: "content_top_box_l_title",
          className_open: "content_top_box_l_titleOpen",
          title: "Docs",
          show: true,
        },
      ],
      topIndex:''
    };
  },
  mounted() {},
  created() {},
  methods: {
     topBtn(e) {
      for (let i in this.topTitleList) {
        this.topTitleList[i].show = true;

      }
      this.topTitleList[e].show = false;

      this.topIndex = e
      window.location.href = this.topTitleList[e].anchor
    },
  },
};
</script>
<style scoped lang='scss'>
.content {
  width: 100%;
  height: 4013px;
  background: url("../assets/image/bg.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  overflow-y: auto;
}
.content_top {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  background: linear-gradient(180deg, #151515 0%, rgba(21, 21, 21, 0.54) 100%);
  z-index: 99;
}
.content_top_box {
  width: 1050px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.content_top_box_l {
  display: flex;
  line-height: 80px;
}
.logo {
  width: 103px;
  height: 42px;
  margin-right: 61px;
}
.image {
  width: 100%;
  height: 100%;
}
.content_top_box_l_titleOpen {
  font-family:"hanson";
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin-right: 43px;
  border-bottom: 3px solid #f79413;
}
.content_top_box_l_title {
  font-family: "hanson";
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin-right: 43px;
}
//
.content_top_box_r {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 211px;
  height: 39px;
  border: 2px solid transparent;
  border-image: linear-gradient(
      180deg,
      rgba(169, 222, 238, 1),
      rgba(169, 222, 238, 1),
      rgba(191, 253, 227, 1)
    )
    2 2;
  margin-top: 21px;
  border-radius: 20px;

}
.content_top_box_r_image {
  width: 20px;
  height: 20px;
}
.content_top_box_r_title {
  height: 23px;
  font-family: "hanson";
  font-weight: 800;
  font-size: 18px;
  color: #ffffff;
  line-height: 23px;
  margin-left: 6px;
}
//
.Bits {
  width: 632px;
  height: 691px;
  position: absolute;
  top: 0;
  right: 0;
}
.walk {
  width: 52px;
  height: 151px;
  position: absolute;
  bottom: -92px;
  right: 190px;
}
//
.theNext {
  display: flex;
  justify-content: center;
}
.theNextBox {
  width: 1050px;
}
.theNextBox_title {
  width: 574px;
  height: 100px;
  font-family: "hanson";
  font-weight: bold;
  font-size: 48px;
  color: #ffffff;
  line-height: 56px;
  padding-top: 238px;
}
.theNext_outside {
  width: 194px;
  height: 10px;
  background: #ffffff;
  border-radius: 20px 20px 20px 20px;
  margin-top: 173px;
  padding-left: 2px;
  overflow: hidden;
}
.theNext_interior {
  width: 55px;
  height: 6px;
  background: #f79413;
  border-radius: 15px 15px 15px 15px;
  margin-top: 2px;
}
.VALUES_title{
  font-family: 'hanson', Hanson;
font-weight: bold;
font-size: 48px;
color: #FFFFFF;
margin-top: 120px;
}
.right{
  width: 192px;
  height: 37px;
  margin-top: 40px;
}
.VALUES_conter{
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
}
.simpleness{
  width: 283px;
height: 31px;
  font-family: Lantinghei SC, Lantinghei SC;
font-weight: 800;
font-size: 22px;
color: #FFFFFF;
}
.create{
  width: 220px;
height: 23px;
font-family: 'hanson', Hanson;
font-weight: bold;
font-size: 22px;
color: #FFFFFF;
margin-top: 47px;
}
.build{
  width: 484px;
height: 66px;
font-family: 'robotoM';
font-weight: 500;
font-size: 28px;
color: #FFFFFF;
margin-top: 34px;
}
.Bring{
  width: 494px;
height: 66px;
font-family: 'robotoM';
// font-weight: 500;
font-size: 28px;
color: #FFFFFF;
line-height: 33px;
margin-top: 34px;

}
.values_r{
  width: 411px;
  height: 378px;
}
//
.FEATURES_title{
  position: relative;
  width: 340px;
height: 50px;
font-family: 'hanson';
font-weight: bold;
font-size: 48px;
color: #FFFFFF;
line-height: 56px;
margin-top: 120px;
z-index: 99;
}
.FEATURES_image{
  position: absolute;
  top: -131px;
  left: 80px;
  width: 86px;
  height: 235px;
  background: url('../assets/image/FEATURES.png') no-repeat;
  background-size: 100% 100%;
  z-index: -10;
}
.FEATURES_conter{
  display: flex;
  margin-top: 55px;
}
.FEATURES_conter_one{
  width: 250px;
height: 380px;
background: rgba(101,143,249,0.2);
 -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%, 20% 0);

}
.one{
  width: 63px;
height: 42px;
font-family: 'hanson';
font-weight: bold;
font-size: 40px;
line-height: 47px;
text-align: left;
font-style: normal;
text-transform: none;
background: linear-gradient(46.48518925122214deg, #FF9E9E 0%, #7CFFE7 41%, #8F7CFF 83%);
 -webkit-background-clip: text;
  color: transparent;
  margin: 32px 0 10px 32px;
}
.one_title{
  width: 218px;
height: 34px;
font-family: 'hanson';
font-weight: bold;
font-size: 16px;
color: #FFFFFF;
line-height: 19px;
margin-left: 16px;
}
.one_content{
  width: 218px;
height: 182px;
font-family: 'robotoM';
font-weight: 500;
font-size: 18px;
color: #FFFFFF;
line-height: 26px;
margin-left: 16px;
margin-top: 14px;
}
.FEATURES_conter_two{
  width: 251px;
height: 380px;
background: #0D2460;
margin-left: 16px;
}
.two{
  width: 68px;
height: 42px;
font-family: Hanson, Hanson;
font-weight: bold;
font-size: 40px;
color: #326FFF;
margin: 32px 0 10px 32px;
}
.FEATURES_conter_three{
    width: 251px;
height: 380px;
background: #3E2818;
margin-left: 16px;
}
.three{
   width: 68px;
height: 42px;
font-family: 'hanson';
font-weight: bold;
font-size: 40px;
color: #FFA96A;
margin: 32px 0 10px 32px;
}
.FEATURES_conter_four{
    width: 251px;
height: 380px;
background: rgba(206,61,219,0.2);
margin-left: 16px;
}
.four{
  width: 68px;
height: 42px;
font-family: 'hanson';
font-weight: bold;
font-size: 40px;
color: #FF67B9;
margin: 32px 0 10px 32px;
}
//
.Friends_title{
    font-family: 'hanson', Hanson;
    font-weight: bold;
    font-size: 48px;
    color: #FFFFFF;
    margin-top: 120px;
}
.Friends_content{
  display: flex;
  margin-top: 55px;
}
.ardizor1{
  width: 333px;
height: 478px;
background: url('../assets/image/friend1.png') no-repeat;
background-size: 330px 277px;
margin-right: 25px;
}
.ardizor2{
  width: 333px;
  height: 478px;
  background: url('../assets/image/friend2.png') no-repeat;
  background-size: 330px 404px;
  margin-right: 25px;
}
.ardizor3{
  width: 333px;
  height: 478px;
  background: url('../assets/image/friend3.png') no-repeat;
  background-size: 330px 384px;
  margin-right: 25px;
}
//
.Contact_title{
  width: 480px;
height: 50px;
font-family: 'hanson';
font-weight: bold;
font-size: 48px;
color: #FFFFFF;
line-height: 56px;
margin-top: 156px;
}
//
.mail{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 783px;
height: 72px;
border-radius: 4px 4px 4px 4px;
border: 2px solid #3E4451;
margin-top: 54px;
margin-left: 20px;
padding: 0 26px;
}
.mail_r{
  width: 36px;
  height: 23px;
}
.mail_text{
  width: 379px;
height: 31px;
font-family: 'hanson';
font-weight: bold;
font-size: 24px;
color: #3E4451;
line-height: 35px;
}
//
.subscribing{
  // width: 497px;
height: 41px;
font-family: 'robotoM';
font-weight: 500;
font-size: 28px;
color: #FFFFFF;
line-height: 41px;
margin-left: 20px;
margin-top: 20px;
}
//
.bottom{
  display: flex;
  justify-content: space-between;
  margin-top: 82px;
}
.bottom_logo{
  width: 102px;
  height: 42px;
}
.bottom_box{
  display: flex;
}
.bottom_icon{
  width: 45px;
  height: 45px;
}
</style>
