var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"content_top_box_l"},[_vm._m(0),_c('div',{staticClass:"menu",on:{"click":function($event){_vm.reveal=!_vm.reveal}}},[(_vm.reveal)?_c('img',{staticClass:"image",attrs:{"src":require("../assets/image/phone/close.png"),"alt":""}}):_c('img',{staticClass:"image",attrs:{"src":require("../assets/image/phone/menu.png"),"alt":""}})]),_c('transition',{attrs:{"name":"menu-fade"}},[(_vm.reveal)?_c('div',{staticClass:"menu_open"},[_vm._l((_vm.topTitleList),function(item,index){return _c('div',{key:index,class:item.show ? item.className : item.className_open,on:{"click":function($event){return _vm.topBtn(index)}}},[_vm._v(" "+_vm._s(item.title))])}),_c('div',{staticClass:"content_top_box_r"},[_c('div',{staticClass:"content_top_box_r_title"},[_vm._v("Contact Us")])])],2):_vm._e()])],1),_vm._m(1),_c('div'),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{staticClass:"image",attrs:{"src":require("../assets/image/phone/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"themNext"},[_c('div',{staticClass:"themNext_title"},[_vm._v("The Next BRC20 Value Network")]),_c('div',{staticClass:"schedule"},[_c('div')]),_c('div',{staticClass:"Bits"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"VALUESbox"},[_c('div',{staticClass:"VALUES_title"},[_vm._v("VALUES")]),_c('div',{staticClass:"VALUES_text_Build"},[_vm._v(" Our vision is to become the basic currency in the “Bitcoin Ecosystem”, mainly used for interaction between “Bitcoin Ecosystem” applications and digital infrastructure. ")]),_c('div',{staticClass:"VALUE_person"},[_c('img',{staticClass:"image",attrs:{"src":require("../assets/image/phone/VALUE_person.png"),"alt":""}})]),_c('div',{staticClass:"VALUE_bottom"},[_c('img',{staticClass:"image",attrs:{"src":require("../assets/image/phone/VALUE_bottom.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"FEATURES"},[_c('div',{staticClass:"VALUES_title"},[_vm._v("FEATURES")]),_c('div',{staticClass:"FEATURES_conter"},[_c('div',{staticClass:"FEATURES_conter_one"},[_c('div',{staticClass:"one"},[_vm._v("01")]),_c('div',{staticClass:"one_title"},[_vm._v("Fast & Secure Infrastructure")]),_c('div',{staticClass:"one_content"},[_vm._v("Build a \"Bit Infrastructure\" like DSN (decentralized storage network） faster than the Bitcoin network POW (L1).")])]),_c('div',{staticClass:"FEATURES_conter_two"},[_c('div',{staticClass:"two"},[_vm._v("02")]),_c('div',{staticClass:"one_title"},[_vm._v("User-friendly Access")]),_c('div',{staticClass:"one_content"},[_vm._v("Users can easily connect wallet to use “Bit Infrastructure” , This will make it easier for persons and enterprises to apply decentralized storage network.")])]),_c('div',{staticClass:"FEATURES_conter_three"},[_c('div',{staticClass:"three"},[_vm._v("03")]),_c('div',{staticClass:"one_title"},[_vm._v("Support multiple applications")]),_c('div',{staticClass:"one_content",staticStyle:{"font-size":"12px"}},[_vm._v("Support more application scenarios, reducing costs and improving efficiency, more users are willing change their storage framework to a decentralized storage network.")])]),_c('div',{staticClass:"FEATURES_conter_four"},[_c('div',{staticClass:"four"},[_vm._v("04")]),_c('div',{staticClass:"one_title"},[_vm._v("Bitcoin Economic Ecosystem")]),_c('div',{staticClass:"one_content"},[_vm._v("Establish a more efficient economic model of producers (network storage providers) and consumers (network storage users) in the Bitcoin ecosystem.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Invested"},[_c('div',{staticClass:"VALUES_title"},[_vm._v("FRIENDS")]),_c('div',{staticClass:"Invested_conter"},[_c('div',{staticClass:"ardizor1"}),_c('div',{staticClass:"ardizor2",staticStyle:{"margin-top":"16px"}}),_c('div',{staticClass:"ardizor3",staticStyle:{"margin-top":"16px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Contact"},[_c('div',{staticClass:"VALUES_title"},[_vm._v("CONTACT US")]),_c('div',{staticClass:"mail"},[_c('div',{staticClass:"mail_text"},[_vm._v("Enter your E-mail")]),_c('div',{staticClass:"mail_r"},[_c('img',{staticClass:"image",attrs:{"src":require("../assets/image/phone/mail_r.png"),"alt":""}})])]),_c('div',{staticClass:"subscribing"},[_vm._v("By subscribing, you accepted our Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base"},[_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"bottom_logo"},[_c('img',{staticClass:"image",attrs:{"src":require("../assets/image/logo.png"),"alt":""}})]),_c('div',{staticClass:"bottom_box"},[_c('div',{staticClass:"bottom_icon"},[_c('img',{staticClass:"image",attrs:{"src":require("../assets/image/bottom_icon1.png"),"alt":""}})]),_c('div',{staticClass:"bottom_icon",staticStyle:{"margin-left":"12px"}},[_c('img',{staticClass:"image",attrs:{"src":require("../assets/image/bottom_icon2.png"),"alt":""}})]),_c('div',{staticClass:"bottom_icon",staticStyle:{"margin-left":"12px"}},[_c('img',{staticClass:"image",attrs:{"src":require("../assets/image/bottom_icon3.png"),"alt":""}})])])])])
}]

export { render, staticRenderFns }