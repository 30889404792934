import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css'
import './assets/css/main.css'
import 'lib-flexible'
import '@/assets/font/hanson.css'
import '@/assets/font/Roboto_Me.css'
import '@/assets/font/roboto_Th.css'
import * as Echarts from 'echarts'
Vue.prototype.$Echarts = Echarts
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
