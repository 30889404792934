import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import MobilePage from '../views/mobilePage.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: window.innerWidth <= 600 ? MobilePage : HomeView
  },
]

const router = new VueRouter({
  mode: 'history', // 使用 history 模式
  routes
})

export default router
