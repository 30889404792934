<template>
  <div class="content">
    <!--  -->
    <div class="content_top_box_l">
      <div class="logo">
        <img src="../assets/image/phone/logo.png" alt="" class="image" />
      </div>
      <div class="menu" @click="reveal=!reveal">
        <img src="../assets/image/phone/close.png" alt="" class="image" v-if="reveal" />
        <img src="../assets/image/phone/menu.png" alt="" class="image" v-else />
      </div>
       <transition name="menu-fade">
      <div class="menu_open" v-if="reveal">
         <div v-for="(item,index) in topTitleList" :key="index"
          @click="topBtn(index)"
            :class="item.show ? item.className : item.className_open">
           {{ item.title }}</div>

         <div class="content_top_box_r">
            <!--div class="content_top_box_r_image">
              <img src="../assets/image/Wallet.png" alt="" class="image" />
            </div-->
            <div class="content_top_box_r_title">Contact Us</div>
          </div>

      </div>
      </transition>
    </div>
    <!-- end -->
    <!--  -->
    <div class="themNext">
      <div class="themNext_title">The Next BRC20 Value Network</div>
      <div class="schedule">
        <div></div>
      </div>
      <div class="Bits"></div>
    </div>
    <div></div>
    <!-- end -->
    <!--  -->
    <div class="VALUESbox">
      <div class="VALUES_title">VALUES</div>
      <!--div class="VALUES_r">
        <img src="../assets/image/phone/r.png" alt="" class="image" />
      </div-->

      <div class="VALUES_text_Build">
          Our vision is to become the basic currency in the “Bitcoin Ecosystem”, mainly used for interaction  between “Bitcoin Ecosystem” applications and digital infrastructure.
      </div>


      <div class="VALUE_person">
        <img
          src="../assets/image/phone/VALUE_person.png"
          alt=""
          class="image"
        />
      </div>
      <div class="VALUE_bottom">
        <img
          src="../assets/image/phone/VALUE_bottom.png"
          alt=""
          class="image"
        />
      </div>
    </div>
    <!-- end -->
    <!--  -->
    <div class="FEATURES">
       <div class="VALUES_title">FEATURES</div>
      <!--div class="VALUES_r">
        <img src="../assets/image/phone/r.png" alt="" class="image" />
      </div-->
       <div class="FEATURES_conter">
          <div class="FEATURES_conter_one">
            <div class="one">01</div>
            <div class="one_title">Fast & Secure Infrastructure</div>
            <div class="one_content">Build a  "Bit Infrastructure" like DSN (decentralized storage network） faster than the Bitcoin network POW (L1).</div>
          </div>
          <div class="FEATURES_conter_two">
            <div class="two">02</div>
            <div class="one_title" >User-friendly Access</div>
            <div class="one_content">Users can easily connect wallet to use “Bit Infrastructure” , This will make it easier for persons and enterprises to apply decentralized storage network.</div>
          </div>
          <div class="FEATURES_conter_three">
            <div class="three">03</div>
            <div class="one_title" >Support multiple applications</div>
            <div class="one_content" style="font-size: 12px;">Support more application scenarios, reducing costs and improving efficiency, more users are willing change their storage framework to a decentralized storage network.</div>

          </div>
          <div class="FEATURES_conter_four">
            <div class="four">04</div>
            <div class="one_title" >Bitcoin Economic Ecosystem</div>
            <div class="one_content">Establish a more efficient economic model of producers (network storage providers) and consumers (network storage users) in the Bitcoin ecosystem.</div>

          </div>
       </div>
    </div>
    <!-- end -->
    <!--  -->
    <div class="Invested">
      <div class="VALUES_title">FRIENDS</div>
      <!--div class="VALUES_r">
        <img src="../assets/image/phone/r.png" alt="" class="image" />
      </div-->
       <div class="Invested_conter">
          <div class="ardizor1"></div>
          <div class="ardizor2" style="margin-top: 16px;"></div>
           <div class="ardizor3" style="margin-top: 16px;"></div>
        </div>
    </div>
    <!-- end -->
    <!--  -->
    <div class="Contact">
       <div class="VALUES_title">CONTACT US</div>
      <!--div class="VALUES_r">
        <img src="../assets/image/phone/r.png" alt="" class="image" />
      </div-->

       <div class="mail">
          <div class="mail_text">Enter your E-mail</div>
          <div class="mail_r">
            <img src="../assets/image/phone/mail_r.png" alt="" class="image">
          </div>
        </div>
        <div class="subscribing">By subscribing, you accepted our Policy</div>
    </div>
    <!-- end -->

    <div class="base">
       <div class="bottom">
          <div class="bottom_logo">
            <img src="../assets/image/logo.png" alt="" class="image">
          </div>
          <div class="bottom_box">
            <div class="bottom_icon">
              <img src="../assets/image/bottom_icon1.png" alt="" class="image">
            </div>
           <div class="bottom_icon" style="margin-left:12px;">
              <img src="../assets/image/bottom_icon2.png" alt="" class="image">
            </div>
            <div class="bottom_icon" style="margin-left:12px;">
              <img src="../assets/image/bottom_icon3.png" alt="" class="image">
            </div>

          </div>
        </div>
    </div>
    <!-- end -->
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      reveal:false,
      topTitleList: [
        {
          anchor: "#About",
          className: "content_top_box_l_title",
          className_open: "content_top_box_l_titleOpen",
          title: "Join Bits",
          show: false,
        },

        {
          anchor: "https://unisat.io/market/brc20?tick=bits",
          title: "Buy Bits",
          className: "content_top_box_l_title",
          className_open: "content_top_box_l_titleOpen",
          show: true,
        },
        {
          anchor: "#Token",
          className: "content_top_box_l_title",
          className_open: "content_top_box_l_titleOpen",
          title: "Docs",
          show: true,
        },
      ],
      topIndex: "",
    };
  },
  mounted() {},
  created() {},
  methods: {
    topBtn(e) {
        for (let i in this.topTitleList) {
            this.topTitleList[i].show = true;

        }
        this.topTitleList[e].show = false;

        this.topIndex = e
        window.location.href = this.topTitleList[e].anchor
    },
  },
};
</script>
<style scoped lang='scss'>
.content {
  width: 100%;
  height: 4013px;
  background: url("../assets/image/phone/mobileBg.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.content_top_box_l {
  position: relative;
  height: 44px;
  display: flex;
  justify-content: space-between;
  line-height: 44px;
  padding: 0 24px;
}
.logo {
  width: 67px;
  height: 29px;
}
.image {
  width: 100%;
  height: 100%;
}
.menu {
  width: 30px;
  height: 30px;
}
.menu_open{
  width: 295px;
  height: 290px;
  position: absolute;
  top:44px;
  left: 24px;
  background: #141414;
  z-index: 100;
  padding-left: 30px;
}
.content_top_box_l_titleOpen {
  height: 21px;
  font-family: Hanson, Hanson;
  font-weight: 500;
  font-size: 18px;
  color: #f58536;
  margin-top: 43px;
}
.content_top_box_l_title {
  height: 21px;
  font-family: Hanson;
  font-weight: 300;
  color: #FFFFFF;
  font-size: 18px;
  margin-top: 43px;

}
//
.content_top_box_r {
  display: flex;
  justify-content: center;
  width: 211px;
  height: 39px;
  border: 2px solid transparent;
  border-radius: 20px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border-image: linear-gradient(
      180deg,
      rgba(169, 222, 238, 1),
      rgba(169, 222, 238, 1),
      rgba(191, 253, 227, 1)
    )
    2 2;
  margin-top: 43px;
}

.content_top_box_r_image {
  width: 20px;
  height: 20px;
}
.content_top_box_r_title {
  font-family: "hanson";
  font-weight: 800;
  font-size: 18px;
  color: #ffffff;
  margin-left: 6px;
}
//
.themNext {
  position: relative;
  padding: 0 24px;
  height: 257px;
  overflow: hidden;
}
.themNext_title {
  width: 281px;
  height: 46px;
  font-family: "hanson";
  font-weight: bold;
  font-size: 22px;
  color: #ffffff;
  line-height: 26px;
  position: absolute;
  top: 20px;
  left: 24px;
  z-index: 99;
}
.schedule {
  width: 85px;
  height: 6px;
  background: #323232;
  border-radius: 15px 15px 15px 15px;
  overflow: hidden;
  margin-top: 132px;
}
.schedule div {
  width: 26px;
  height: 6px;
  background: #f79413;
}
.Bits {
  width: 289px;
  height: 257px;
  background: url("../assets/image/phone/Bits.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: -5px;
  right: 0;
  z-index: 1;
}
//
.VALUESbox {
  position: relative;
  padding: 0 24px;
  margin-top: 42px;
}
.VALUES_title {
  height: 23px;
  font-family: "hanson", Hanson;
  font-weight: bold;
  font-size: 22px;
  color: #ffffff;
  line-height: 26px;
}
.VALUES_r {
  width: 133px;
  height: 15px;
  margin-top: 16px;
  margin-bottom: 32px;
}
.VALUES_text_o {
  width: 206px;
  height: 22px;
  font-family: Lantinghei SC, Lantinghei SC;
  font-weight: 800;
  font-size: 16px;
  color: #ffffff;
  line-height: 19px;
}
.VALUES_text_Create {
  height: 19px;
  font-family: "hanson", Hanson;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  line-height: 21px;
  margin-top: 12px;
}
.VALUES_text_Build {
  width: 311px;
  height: 42px;
  font-family: "robotoThin";
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  line-height: 21px;
  margin-top: 22px;
}
.VALUE_person {
  width: 52px;
  height: 151px;
  position: absolute;
  top: -11px;
  right: 36px;
}
.VALUE_bottom {
  width: 327px;
  height: 300px;
  margin-top: 96px;
}
//
.FEATURES{
  padding: 0 24px;
  margin-top: 52px;
}
.FEATURES_conter{
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
  margin-top: 55px;
}
.FEATURES_conter_one{
  width: 180px;
height: 262px;
background: rgba(101,143,249,0.2);
 -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%, 20% 0);

}
.one{
  width: 32px;
height: 21px;
font-family: 'hanson';
font-weight: bold;
font-size: 20px;
line-height: 23px;
text-align: left;
font-style: normal;
text-transform: none;
background: linear-gradient(46.48518925122214deg, #FF9E9E 0%, #7CFFE7 41%, #8F7CFF 83%);
 -webkit-background-clip: text;
  color: transparent;
  margin: 17px 0 8px 24px;
}
.one_title{
  width: 140px;
height: 30px;
font-family: 'hanson';
font-weight: bold;
font-size: 14px;
color: #FFFFFF;
line-height: 16px;
margin-left: 12px;
}
.one_content{
  width: 136px;
height: 119px;
font-family: 'robotoM';
font-weight: 500;
font-size: 12px;
color: #FFFFFF;
line-height: 17px;
margin-left: 12px;
margin-top: 24px;
}
.FEATURES_conter_two{
  width: 180px;
height: 262px;
background: #0D2460;
}
.two{
  width: 34px;
height: 21px;
font-family: Hanson, Hanson;
font-weight: bold;
font-size: 20px;
color: #326FFF;
margin: 17px 0 8px 24px;
}
.FEATURES_conter_three{
    width: 180px;
height: 262px;
background: #3E2818;
margin-top: 8px;
}
.three{
   width: 34px;
height: 21px;
font-family: 'hanson';
font-weight: bold;
font-size: 20px;
color: #FFA96A;
margin: 17px 0 8px 24px;
}
.FEATURES_conter_four{
    width: 180px;
height: 262px;
background: rgba(206,61,219,0.2);
margin-top: 8px;
}
.four{
  width: 34px;
height: 21px;
font-family: 'hanson';
font-weight: bold;
font-size: 20px;
color: #FF67B9;
margin: 17px 0 8px 24px;
}
//
.InvestedBy_box{
  padding: 0 24px;
  margin-top: 52px;
}
.brand{
  width: 150px;
height: 48px;
background: #FFFFFF;
border-radius: 10px 10px 10px 10px;
}
.SHIMA{
  width: 120px;
  height: 18px;
  margin: auto;
}
//
.Invested{
  padding: 0 24px;
  margin-top: 52px;
}
.Invested_conter{
    padding: 0 24px;
    margin-top: 52px;
}

.ardizor1{
    width: 333px;
    height: 328px;
    background: url('../assets/image/friend1.png') no-repeat;
    background-size: 330px 277px;
    margin-right: 25px;
}
.ardizor2{
    width: 333px;
    height: 478px;
    background: url('../assets/image/friend2.png') no-repeat;
    background-size: 330px 404px;
    margin-right: 25px;
}
.ardizor3{
    width: 333px;
    height: 478px;
    background: url('../assets/image/friend3.png') no-repeat;
    background-size: 330px 384px;
    margin-right: 25px;
}
//
.Contact{
  padding: 0 24px;
  margin-top: 52px;
}
//
.mail{
  display: flex;
  justify-content: space-between;
  align-items: center;
height: 45px;
border-radius: 4px 4px 4px 4px;
border: 2px solid #3E4451;
margin-top: 27px;
padding: 0 26px;
}
.mail_r{
  width: 35px;
  height: 15px;
}
.mail_text{
  width: 177px;
height: 15px;
font-family: 'hanson';
font-weight: bold;
font-size: 14px;
color: #3E4451;
line-height: 16px;
}
//
.subscribing{
height: 41px;
font-family: 'robotoM';
font-weight: 500;
font-size: 14px;
color: #FFFFFF;
line-height: 41px;
}
//
.base{
  padding: 0 24px;
}
.bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 68px;
}
.bottom_logo{
  width: 102px;
  height: 42px;
}
.bottom_box{
  display: flex;
}
.bottom_icon{
  width: 28px;
  height: 28px;
}


//
/* 定义过渡动画样式 */
.menu-fade-enter-active,
.menu-fade-leave-active {
  transition: all 0.5s ease;
}

.menu-fade-enter,
.menu-fade-leave-to {
  opacity: 0;
  transform: translateY(-10px); /* 可选，添加垂直方向上的移动以增强过渡效果 */
}
</style>
